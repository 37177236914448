
import { defineComponent } from "vue";
import { Puitesopimus } from "../@types/Puitesopimus";
import { formattedDate } from "@/utils/date";
import { useRouter } from "vue-router";

export default defineComponent({
    props: {
        puitesopimukset: Array,
    },
    setup() {
        const router = useRouter();

        const validity = (puitesopimus: Puitesopimus) => {
            const { valid_until } = puitesopimus;
            const isValid = new Date(puitesopimus.valid_until) > new Date();
            const formatted = formattedDate(valid_until);
            return { isValid, text: isValid ? `${formatted} asti` : `päättynyt ${formatted}` };
        };

        const toPuitesopimusPage = (uuid: string) => {
            router.push(`/puitesopimukset/${uuid}`);
        };

        return { validity, toPuitesopimusPage };
    },
});
