const EMAIL_REGEX =
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
export const emailRegex = RegExp(EMAIL_REGEX);

const PHONE_REGEX = "^[0-9()\\-+ ]*$";
export const phoneRegex = RegExp(PHONE_REGEX);

const WEBSITE_REGEX =
    "^http(s)?://[a-zA-Z0-9äÄöÖåÅ@:%._+~#=]{2,256}\\.[a-z]{2,6}(/)?[-a-zA-Z0-9äÄöÖåÅ@:%_+.~#?&/=]*$";
export const websiteRegex = RegExp(WEBSITE_REGEX);

const POSTCODE_REGEX = "^[0-9]{5}$";
export const postcodeRegex = RegExp(POSTCODE_REGEX);

const POS_INT_REGEX = "^[0-9]+$";
export const posIntRegex = RegExp(POS_INT_REGEX);

const PRICE_REGEX = "^[0-9]+([.,][0-9]+)?$";
export const priceRegex = RegExp(PRICE_REGEX);

const DATE_REGEX = "^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)[0-9]{2}$";
export const dateRegex = RegExp(DATE_REGEX);
