
import { defineComponent, onMounted, computed } from "vue";
import { useStore } from "vuex";
import PuitesopimuksetList from "../components/PuitesopimuksetList.vue";
import { Puitesopimus } from "../@types/Puitesopimus";
import { useRouter } from "vue-router";

export default defineComponent({
    components: { PuitesopimuksetList },
    setup() {
        const store = useStore();
        const router = useRouter();

        const fetchPuitesopimukset = () => store.dispatch("puitesopimukset/fetchPuitesopimukset");
        onMounted(fetchPuitesopimukset);

        const toAddPuitesopimusPage = () => {
            router.push("/puitesopimukset/new");
        };

        const puitesopimukset = computed(() =>
            [...store.state.puitesopimukset.puitesopimukset].sort(
                (p1: Puitesopimus, p2: Puitesopimus) =>
                    new Date(p1.valid_until) > new Date(p2.valid_until) ? -1 : 1
            )
        );

        return { puitesopimukset, toAddPuitesopimusPage };
    },
});
