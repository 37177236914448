import { date as DateUtils } from "quasar";
import { dateRegex } from "./regex";

export interface DateRange {
    from: string;
    to: string;
}

export function formatDate(date: Date, format?: string): string {
    return DateUtils.formatDate(date, format || "DD.MM.YYYY");
}

export function formattedYear(date: Date): string {
    return DateUtils.formatDate(date, "YYYY");
}

export function formattedDate(dateStr: string): string {
    const date = new Date(dateStr);
    return formatDate(date);
}

export function formattedDateRange({ from, to }: DateRange) {
    const fromDate = new Date(from);
    const toDate = new Date(to);
    return `${formatDate(fromDate)} - ${formatDate(toDate)}`;
}

export function localStringToDate(dateStr: string): Date | null {
    if (!dateStr || dateStr.length < 10) {
        return null;
    } else if (dateStr.length === 10 && dateStr.includes(".")) {
        // test regex only for dates that are filled by keyboard forced in format dd.mm.yyyy
        return dateRegex.test(dateStr) ? DateUtils.extractDate(dateStr, "DD.MM.YYYY") : null;
    } else {
        return new Date(dateStr);
    }
}

export const mask = "YYYY/MM/DD";
export const locale = {
    days: [
        "Sunnuntai",
        "Maanantai",
        "Tiistai",
        "Keskiviikko",
        "Torstai",
        "Perjantai",
        "Lauantai",
        "Sunnuntai",
    ],
    daysShort: ["Su", "Ma", "Ti", "Ke", "To", "Pe", "La", "Su"],
    months: [
        "Tammikuu",
        "Helmikuu",
        "Maaliskuu",
        "Huhtikuu",
        "Toukokuu",
        "Kesäkuu",
        "Heinäkuu",
        "Elokuu",
        "Syyskuu",
        "Lokakuu",
        "Marraskuu",
        "Joulukuu",
    ],
    monthsShort: [
        "Tam",
        "Hel",
        "Maa",
        "Huh",
        "Tou",
        "Kes",
        "Hei",
        "Elo",
        "Syys",
        "Loka",
        "Mar",
        "Jou",
    ],
};
